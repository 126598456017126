<template>
  <date-picker
    format="DD/MM/YYYY"
    title-format="DD/MM/YYYY"
    value-type="YYYY-MM-DD"
    input-class="form-control"
    v-model="selectedDate"
    class="grow"
  />
</template>
<script type="text/javascript">
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';

export default {
  components: {
    DatePicker,
  },
  props: ['value'],
  data() {
    return {
      selectedDate: '',
    };
  },
  methods: {
    setInitialValue() {
      this.selectedDate = this.value;
    },
    formatDate(date) {
      return date.toLocaleDateString();
    },
  },
  watch: {
    value: {
      handler() {
        this.setInitialValue();
      },
    },
    selectedDate: {
      handler() {
        this.$emit('input', this.selectedDate);
      },
    },
  },
  mounted() {
    this.setInitialValue();
  },
};
</script>
<style scoped>
.grow{
  width:100%;
}
</style>
