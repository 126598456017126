<template>
  <select class="form-control" v-model="selected_id">
    <option v-for="(item, index) in items" :key="index" :value="item.key">
      {{ item.value }}
    </option>
  </select>
</template>
<script type="text/javascript">
export default {
  props: ['value', 'items'],
  data() {
    return {
      selected_id: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.selected_id = this.value;
      },
    },
    selected_id: {
      handler() {
        this.$emit('input', this.selected_id);
      },
    },
  },
};
</script>
